$toppadding: 50px;
$weeknumpadding: 10px;

.primary_settings {
  padding: 2px 20px;

  text-align: left;
}

.time_input {
  border-radius: 5px;
  padding: 5px;
  border: none;
  text-align: center;
  color: rgba(map-get($color-index, body), 1);
  font-weight: 800;
}
.timesheet {
  .days-container {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    gap: 50px;

    margin: 0 auto;
    color: rgba(map-get($color-index, dashblue), 1);
    //margin-top: -20px !important;
    //cursor: pointer;
    h1 {
      font-size: 25px;
      color: rgba(map-get($color-index, dashblue), 1);
      text-align: center;
    }

    .select {
      width: 100%;

      margin: 5px 0;
      margin-top: 0;
      padding: 7px;
      border-radius: 0 0 10px 10px;
    }

    .button__select {
      display: flex;
      justify-content: center;
      button,
      button.sumbutton {
        background-color: rgba(map-get($color-index, lightblue), 1);

        &:first-of-type {
          border-radius: 10px 0 0 0;
        }
        &:last-of-type {
          border-radius: 0 10px 0 0;
        }
        text-transform: uppercase;
        width: 50%;
        padding: 5px;
        margin: 5px 0;
        border: none;
        &.active {
          background-color: rgba(map-get($color-index, dashblue), 1);
          color: #fff;

          &:hover {
            -webkit-filter: invert(100%);
            filter: invert(100%);
          }
        }
        margin-bottom: 0;
      }
      button.sumbutton {
        width: 50%;
        @media (min-width: 600px) {
          width: 40%;
        }
        &:first-of-type {
          border-radius: 10px 0 0 10px;
        }
        &:last-of-type {
          border-radius: 0 10px 10px 0;
        }
      }
    }

    .week {
      position: relative;
      .watermark {
        font-size: 250px;
        text-align: center;

        text-transform: uppercase;
        opacity: 0.2;
        color: rgba(map-get($color-index, dashblue), 1);
        position: absolute;
        top: 50%;
        left: 50%;

        @media (min-width: 600px) {
          left: 43%;
        }

        transform: translate(-50%, -50%);
        z-index: -100;
      }

      .day {
        margin: 2px;
        background-color: rgba(247, 244, 244, 0.5);
        display: grid;

        grid-template-columns: 3fr 8fr 1fr 1fr 2fr;
        align-items: center;

        text-align: center;
        color: rgba(map-get($color-index, body), 1);

        &__name {
          // @extend .primary_settings;
          font-weight: 600;
          text-transform: uppercase;
          // margin: 5px 0;
        }

        &__date {
          font-size: 13px;
          color: rgba(map-get($color-index, dashblue), 1);
        }

        &__title {
          align-self: center;

          &--container {
            position: relative !important;
          }
        }

        &__summary {
          @extend .primary_settings;
          margin: 0 auto;
          width: 100%;
          align-self: center;

          @each $bgcolor in $color-index {
            &--#{nth($bgcolor,1)} {
              background-color: nth($bgcolor, 2);

              @extend .time_input;

              &:hover {
                background: darken(nth($bgcolor, 2), 15%);
                transition: all 0.3s ease;
              }
            }
          }
        }

        &__icon {
          align-self: center;
          font-size: 25px;
          color: rgba(map-get($color-index, dashblue), 1);
          &:hover {
            -webkit-filter: invert(100%);
            filter: invert(100%);
          }
        }

        &:hover {
          cursor: pointer;
          background-color: rgba(map-get($color-index, lightblue), 0.5);
        }

        &__error {
          background-color: rgba(map-get($color-index, red), 0.3);
          &:hover {
            background-color: rgba(map-get($color-index, red), 0.5);
          }
        }
      }
    }
    .sum {
      position: relative;
      padding-bottom: 100px;

      @media (min-width: 600px) {
        padding-bottom: 150px;
      }

      &__total {
        position: absolute;
        right: 20px;
        text-align: right;

        @extend .time_input;
      }
    }
    .currentday {
      background-color: rgba(map-get($color-index, dashboard), 0.3) !important;
      //opacity: 0.3;
    }

    .weeknum {
      padding: $weeknumpadding 0;
      display: flex;

      align-items: center;

      h2 {
        border-radius: 50%;
        width: 50px;
        height: $toppadding;
        margin: 0 auto;
        padding: 8px;
        text-align: center;
        background-color: rgba(map-get($color-index, dashblue), 1);
        font-size: 25px !important;
        font-weight: 600;
        color: #fff;
        align-items: center;
      }

      h4 {
        display: flex;
        align-items: center;
        &:hover {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
      }
    }
  }

  .projects {
    color: #fff;
  }
}

td {
  .day__summary {
    @each $bgcolor in $color-index {
      &--#{nth($bgcolor,1)} {
        background-color: nth($bgcolor, 2);

        @extend .time_input;

        &:hover {
          background: darken(nth($bgcolor, 2), 15%);
          transition: all 0.3s ease;
        }
      }
    }
  }
}
//timesheet summerize based on projects
@import "./TimeSheetSummericeProjects";
@import "./TimeSheetSummericeWeeks";
