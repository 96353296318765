.container.card {
  background: #2f334b;
}
.card {
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  gap: 20px;

  justify-content: space-between;

  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  .card-body {
    padding: 0 !important;
  }

  .users {
    color: #2f334b;
    ul li {
      display: flex;

      i {
        padding: 5px;
        padding-right: 10px;
        display: flex;
        align-items: center;
      }
    }

    &:nth-child(2n + 2) {
      background: #20253f;
      color: #fff;

      ul li {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.125);
      }
    }

    margin: 0 !important;

    h5 {
      font-weight: 800;
      text-align: center;
      padding: 10px;
      text-transform: uppercase;
    }
    img {
      max-width: 150px;
      max-height: 150px;
      border-radius: 50%;

      object-fit: fill;
      margin: 0 auto;

      -moz-box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
      -webkit-box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
      box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    }

    .btn {
      @each $bgcolor in $color-index {
        &--#{nth($bgcolor,1)} {
          background-color: nth($bgcolor, 2);
          color: #fff;

          &:hover {
            background: darken(nth($bgcolor, 2), 10%);
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.userprofile {
  background-color: rgba(map-get($color-index, dashboard), 1);

  .menu {
    background-color: #0eaaac;
    padding: 8px;
    text-align: center;
  }

  color: #fff;

  ul li {
    list-style-type: none;
    padding: 0;



    img.card-image {
      border-radius: 50%;
      width: 50%;
      margin-left: 50%;
      transform: translateX(-50%);

      
    }
  }

  .btn {
    border-radius: 0;
    width: 100%;
    margin-top: 4px;
    @each $bgcolor in $color-index {
      &--#{nth($bgcolor,1)} {
        background-color: nth($bgcolor, 2);
        color: #fff;

        &:hover {
          background: darken(nth($bgcolor, 2), 10%);
          transition: all 0.3s ease;
        }
      }
    }
  }
}
