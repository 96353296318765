.login {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  background-color: rgba(map-get($color-index, lightblue), 1);

  article {
    display: none;

    @media (min-width: 600px) {
      display: block;
    }

    background-color: rgba(map-get($color-index, dashblue), 1);
  }

  form {
    padding: 10px;

    @media (min-width: 600px) {
      padding: 100px;
    }

    .form-element {
      position: relative;
      input {
        padding: 8px;
        border-radius: 8px;
        border: solid 1px rgba(map-get($color-index, dashblue), 1);

        &:not([type="submit"]) {
          width: 100%;
        }

        &[type="submit"] {
          width: 100%;

          @media (min-width: 600px) {
            width: auto;
          }
        }

        box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
      }
      .svg-inline--fa {
        position: absolute;
        left: 90%;

        top: 50%;
        transform: translateY(-50%);

        color: rgba(map-get($color-index, dashblue), 1);
        z-index: 100;
        font-size: 25px;
      }
    }
  }
}
