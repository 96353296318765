$toppadding: 50px;
$weeknumpadding: 10px;

.current {
  padding-top: $weeknumpadding * 2;

  @media (min-width: 600px) {
    padding-top: $toppadding - $weeknumpadding*2;
  }

  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

  gap: 20px;

  align-items: flex-start;

  &__jobs {
    tr:first-of-type {
      th {
        background-color: rgba(map-get($color-index, dashblue), 1);
        color: #fff;
        padding: 5px;

        text-align: center;
        text-transform: uppercase;
        border-radius: 10px 10px 0 0;
      }
    }

    tr:not(:first-of-type),
    tr:not(:last-of-type) {
      &:nth-child(even) {
        background-color: rgba(map-get($color-index, lightblue), 0.3);
      }
      &:nth-child(odd) {
        background-color: rgba(map-get($color-index, lightblue), 0.1);
      }
    }

    tr:not(:first-of-type) {
      th {
        background-color: rgba(map-get($color-index, lightblue), 1);
        color: rgba(map-get($color-index, dashblue), 1);
        padding: 5px;
      }
    }
    td {
      padding: 5px;
    }

    tbody > tr {
      &:hover {
        background-color: rgba(map-get($color-index, lightblue), 1) !important;
        cursor: pointer;
      }
    }
  }
}
