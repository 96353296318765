.navs {
  margin-top: 20px;
}

#EZDrawer__container {
  background-color: rgba(map-get($color-index, dashboard), 1) !important;
}

.sidebarnav {
  padding: 0;
  margin: 0;

  .nav-item {
    list-style-type: none;

    .nav-link {
      padding: 10px;
      display: block;
      width: 100%;

      margin: 1px 0;

      color: #fff;
      // text-align: center;

      // border-radius: 0 10px 10px 0;

      @each $bgcolor in $color-index {
        &--#{nth($bgcolor,1)} {
          background-color: nth($bgcolor, 2);

          &:hover {
            background-color: nth($bgcolor, 2);

            border-left: solid 5px nth($bgcolor, 2);
          }
        }
      }

      &__leftborder {
        @each $bgcolor in $color-index {
          &--#{nth($bgcolor,1)} {
            border-left: solid 5px nth($bgcolor, 2);

            &:hover {
              border-left: solid 5px nth($bgcolor, 2);
              -webkit-filter: invert(80%);
              filter: invert(80%);
            }
          }
        }
      }
    }
  }

  &__image {
    display: flex;
    margin-top: 40px;

    img {
      margin: 0 auto;
      border-radius: 50%;
      border: solid 8px #fff;
      width: 50%;
    }
  }
}
.sidebarnav.login {
  .nav-link {
    background-color: #2f334b;
    border-left: solid 5px rgb(31, 135, 81);

    &:hover {
      background: darken(#2c3e50, 10%);
    }
  }
}
