$dashpad: 20px;

$main_blue: #0969c2;

.DashWrapper {
  background-color: #fff;

  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-transform: uppercase;
    color: $main_blue;
  }

  border: none;

  padding: 0;

  border-radius: 0;

  @media (min-width: 600px) {
    background-color: rgba(map-get($color-index, dashboard), 0.8);

    border: solid 2px rgba(map-get($color-index, dashboard), 1);

    padding: $dashpad 0 0 0;

    border-radius: 30px;

    h1 {
      color: #fff;
    }
  }

  .svg-inline--fa {
    font-size: 75px;
    color: #fff;
  }

  .DashBoard {
    background-color: rgba(map-get($color-index, body), 1);

    border: none;

    padding: 0;

    border-radius: 0;

    @media (min-width: 600px) {
      border: solid 2px rgba(map-get($color-index, dashboard), 1);

      padding: $dashpad;

      border-radius: 27px;
    }

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    gap: 20px;

    &Card {
      @each $bgcolor in $color-index {
        &--#{nth($bgcolor,1)} {
          border: solid 4px nth($bgcolor, 2);

          /* &:hover {
                background: darken(nth($bgcolor,2), 8%);
                transition: all 0.3s ease;
              }*/
        }
      }

      cursor: pointer;

      border-radius: 15px;

      box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

      height: 250px;

      &__Figure {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 50%;
        display: flex;

        justify-content: center;
        align-items: center;
      }

      &__Title {
        display: flex;
        color: #fff;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 50%;
        border-radius: 0 0 10px 10px;

        @each $bgcolor in $color-index {
          &--#{nth($bgcolor,1)} {
            background-color: nth($bgcolor, 2) + AA;

            &:hover {
              background: darken(nth($bgcolor, 2), 15%);
              transition: all 0.3s ease;
            }
          }
        }
      }
    }
  }
}
