.bounce {
  position: absolute;
  left: 50%;
  bottom: -15px;
  margin-top: -90px;
  margin-left: 0;
  height: 30px;
  width: 30px;
  font-size: 40px;
  color: rgba(map-get($color-index, red), 0.5);
  -webkit-animation: bounce 1s infinite;
  -moz-animation: bounce 1s infinite;
  -o-animation: bounce 1s infinite;
  animation: bounce 1s infinite;
  z-index: 500;
}

@-webkit-keyframes bounce {
  0% {
    bottom: -15px;
  }
  50% {
    bottom: 2px;
  }
  100% {
    bottom: 15;
  }
}

@-moz-keyframes bounce {
  0% {
    bottom: -15px;
  }
  50% {
    bottom: 5px;
  }
  100% {
    bottom: 30;
  }
}

@-o-keyframes bounce {
  0% {
    bottom: -15px;
  }
  50% {
    bottom: 15px;
  }
  100% {
    bottom: 30;
  }
}

@keyframes bounce {
  0% {
    bottom: -15px;
  }
  50% {
    bottom: 15px;
  }
  100% {
    bottom: 30;
  }
}
