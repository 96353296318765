:root {
  --icon_width: 35px;
  --btn_color_knife: #0f364f;
  --btn_color_scissor: #0f364f;
}

.jobsform {
  max-width: 1080px;
  margin: 0 auto;
}

.customerform {
  max-width: 1080px;
  margin: 0 auto;
}

.register {
  background-color: rgba(map-get($color-index, dashboard), 1);

  padding: 20px;

  width: 100%;

  max-width: 1080px;
  margin: 0 auto;

  &__img {
    margin-left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  }

  &__form {
    color: #fff;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    gap: 20px;
  }
}

.absenceform {
  .ui-toggle {
    margin: 0;
    padding: 0;
    margin-top: 8px;
  }

  div.ui-toggle input[type="checkbox"] {
    display: none;
  }

  div.ui-toggle input[type="checkbox"]:checked + label {
    border-color: var(--btn_color_knife);
    background: var(--btn_color_knife);
    box-shadow: inset 0 0 0 10px var(--btn_color_knife);
  }

  div.ui-toggle input[type="checkbox"]:checked + label > div {
    margin-left: 20px;
  }

  div.ui-toggle label {
    transition: all 200ms ease;
    display: inline-block;
    position: relative;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: #8c8c8c;
    box-shadow: inset 0 0 0 0 var(--btn_color_knife);
    border: 2px solid #8c8c8c;
    border-radius: 21px;
    width: 44px;
    height: 26px;
  }

  div.ui-toggle label div {
    transition: all 200ms ease;
    background: #fff;
    width: 20px;
    height: 22px;
    border-radius: 10px;
  }

  div.ui-toggle label:hover,
  div.ui-toggle label > div:hover {
    cursor: pointer;
  }

  div.ui-toggle.mb-10 {
    margin-bottom: 10px;
  }
}
