.user_roles {
  .user {
    display: grid;

    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    gap: 40px;

    figure {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}
