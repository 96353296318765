$color-index: (
  dashblue: #175c8d,
  dashboard: #2e4349,
  body: #2e4349,
  midblue: #3895c8,
  lightblue: #c3dbef,
  red: #cc324c,
  orange: #ff8300,
  navitems: #3d515c,
  daymark: #d4dadf,
);



@function color-index($color-key) {
  @return map-get($color-index, $color-key);
}
